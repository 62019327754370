<template>
  <div>

    <!-- Media -->
    <b-media class="mb-2 d-flex align-items-center h-100">
      <template #aside>
      
        <!-- <b-avatar
          ref="previewEl"
          :src="BASE_URL+departmentData.image"
          :text="avatarText(departmentData.name)"
          :variant="`light-${resolveUserRoleVariant(userData.role)}`"
          size="90px"
          rounded
        /> -->
      </template>
      <h4 class="mb-1 " style="text-transform: capitalize; font-weight: bold">
      {{ departmentData.name }} Departmanı<br>
        
      </h4>
      <!-- <div class="d-flex flex-wrap">
        <b-button
          variant="primary"
          @click="$refs.refInputEl.click()"
        >
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            @input="inputImageRenderer"
          >
          <span class="d-none d-sm-inline">Fotoğraf Yükle</span>
          <feather-icon
            icon="EditIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
        <b-button
          variant="outline-secondary"
          class="ml-1"
        >
          <span class="d-none d-sm-inline">Sil</span>
          <feather-icon
            icon="TrashIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
      </div> -->
    </b-media>

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>

        <!-- Field: Username -->
        <!-- <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Bilgisayar Adı"
            label-for="username"
          >
            <b-form-input
              id="username"
              v-model="userData.computerName"
              disabled
              placeholder="Test-12C"
            />
          </b-form-group>
        </b-col> -->

        <!-- Field: Full Name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Ad"
            label-for="full-name"
          >
            <b-form-input
              id="full-name"
              v-model="departmentData.name "
            />
          </b-form-group>
        </b-col>

        <!-- Field: last Name -->
        <!-- <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Soyad"
            label-for="full-name"
          >
            <b-form-input
              id="full-name"
              v-model="userData.lastname "
            />
          </b-form-group>
        </b-col> -->
        <!-- Field: Email -->
        <!-- <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="userData.email"
              type="email"
            />
          </b-form-group>
        </b-col> -->

        <!-- Field: Status -->
        <!-- <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Departman Rolu"
            label-for="user-status"
          >
            <v-select
              v-model="userData.isManager"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="departmentRoles"
              :reduce="val => val.value"
              :clearable="false"
              disable
              input-id="user-status"
            />
          </b-form-group>
        </b-col> -->

        <!-- Field: Role -->
        <!-- <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Kullanıcı Rolu"
            label-for="user-role"
          >
         
            <v-select
              v-model="userData.isAdmin"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="roleOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="user-role"
            />
          </b-form-group>
        </b-col> -->
  
  <!-- Field: Department -->
  <!-- <b-col
          cols="12"
          md="4"
          v-if="departmentList.length>0"
         
        >
          <b-form-group
            label="Departman"
            label-for="user-role"
          >
            <v-select
            v-if="!isLoading"
              v-model="department"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="departmentList"
              :reduce="val => val.value"
              :clearable="false"
              input-id="user-role"
            />
          </b-form-group>
        </b-col> -->
        <!-- Field: Email -->
        <!-- <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Şifre"
            label-for="company"
          >
            <b-form-input
              id="company"
              v-model="password"
            />
          </b-form-group>
        </b-col> -->

         <!-- Field:tel  -->
         <!-- <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Telefon"
            label-for="company"
          >
            <b-form-input
              id="company"
              v-model="userData.phone"
              @input="validatePhoneNumber"
    pattern="[0-9]{11}"
    title="11 haneli bir numara giriniz"
    maxlength="11"

            />
          </b-form-group>
        </b-col> -->

      </b-row>
    </b-form>

    <!-- PERMISSION TABLE -->
    <!-- <b-card
      no-body
      class="border mt-1"
    >
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon
            icon="LockIcon"
            size="18"
          />
          <span class="align-middle ml-50">Permission</span>
        </b-card-title>
      </b-card-header>
      <b-table
        striped
        responsive
        class="mb-0"
        :items="permissionsData"
      >
        <template #cell(module)="data">
          {{ data.value }}
        </template>
        <template #cell()="data">
          <b-form-checkbox :checked="data.value" />
        </template>
      </b-table>
    </b-card> -->

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
    
      @click="userUpdate"
    >
      Güncelle
    </b-button>
    <router-link :to="{ name: 'department-settings'}"> <b-button
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      İptal
    </b-button></router-link>
    <b-button
      variant="danger"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right"
    
      @click="departmentDelete"
    >
     Departmanı Sil
    </b-button>
   
  </div>
</template>

<script>
  import { Api } from '@/helpers/Api/apiConfig'
  import { BASE_URL } from '@/helpers/Url/BaseUrl'
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import useUsersList from '../users-edit/useUsersList'

export default {
  components: {
    BASE_URL,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
  },
  
  // props: {
  //   userData: {
  //     type: Object,
  //     required: true,
  //   },
  //  },
  // watch: {
  //   userData: {
  //     handler(val) {
  //       this.userData = val
  //     }
  //   }
  // },
  data() {
    return {
      departmentData:null,
      //userDepartmentId:null,
    
    }
  },
  setup(props) {
  
    const { resolveUserRoleVariant } = useUsersList()

    const roleOptions = [
      { label: 'Admin', value: true },
      { label: 'Kullanıcı', value: false },
      // { label: 'Editor', value: 'editor' },
      // { label: 'Maintainer', value: 'maintainer' },
      // { label: 'Subscriber', value: 'subscriber' },
    ]
    const departmentList = [
      // { label: 'Admin', value: true },
      // { label: 'Kullanıcı', value: false },
      // { label: 'Editor', value: 'editor' },
      // { label: 'Maintainer', value: 'maintainer' },
      // { label: 'Subscriber', value: 'subscriber' },
    ]
    const departmentRoles = [
     // { label: 'Pending', value: 'pending' },
      { label: 'Yönetici', value: true },
      { label: 'Kullanıcı', value: false },
    ]

    const permissionsData = [
      {
        module: 'Admin',
        read: true,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'Staff',
        read: false,
        write: true,
        create: false,
        delete: false,
      },
      {
        module: 'Author',
        read: true,
        write: false,
        create: true,
        delete: false,
      },
      {
        module: 'Contributor',
        read: false,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'User',
        read: false,
        write: false,
        create: false,
        delete: true,
      },
    ]

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

const department = ref('sd');
const isLoading = ref(false);
const password = ref('');

const departmentParentId=ref(null);

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.image = base64
    })

    return {
      departmentParentId,
      resolveUserRoleVariant,
      avatarText,
      roleOptions,
      departmentRoles,
      permissionsData,
      departmentList,
      department,
      isLoading,
      password,
      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
  mounted() {
   this.getUserDepartment()

    //this.getDepartmentsDetail();
  },
  methods: {
    // validatePhoneNumber() {
    //   // Telefon numarasının uzunluğunu kontrol et
    //   if (this.userData.phone.length > 11) {
    //     // Eğer 11 haneliden fazla ise, son girişi iptal et
    //     this.userData.phone = this.userData.phone.slice(0, 11);
    //   }
    // },
    // async getDepartmentsDetail() {
    //   this.isLoading = true;
    //   await Api("GET", "Departments").then((res) => {
    //     this.departments = res.data.data;
    //     this.departmentList = this.departments.map((item) => {
    //       return {
    //         label: item.name,
    //         value: item.id,
    //         //isActive:false
    //       };
    //     });
    //     // this.$emit('departmentList',this.departmentList)
    //     console.log( 'userreditdepartments',this.departments);
    //     this.getUserDepartment();
    //     this.isLoading = false;
    //   });
    // },
    async getUserDepartment() {
      this.isLoading = true;
      let departmentId = this.$route.params.id;
      
      await Api("GET", "Department/"+departmentId ).then((res) => {
        console.log( 'ress departman edit',res.data.data);
        this.departmentData = res.data.data
        this.departmentParentId = res.data.data.parentId
      //   let departmentId = res.data.data[0].departmentId;
      //   this.department = this.departmentList.find((item) => {
      //     return item.value === departmentId;
      //   }).value
       
      //   console.log( 'userreditdepartments',this.departments,'user',res.data.data[0].user);
      //   this.userDepartmentId = res.data.data[0].id
      //   this.userData == null?this.userData = res.data.data[0].user:null
      //   this.userData = {...this.userData,isManager:res.data.data[0].isManager }
      // console.log('77',this.userData);
        this.isLoading = false;
      });
    },
    async departmentDelete(){
      let departmentId = this.$route.params.id
      await Api("DELETE", "Department/"+departmentId ).then((res) => {
        alert(res.data.message=='OK' ?'Departman sistemden silindi.':' Departman sistemden silinemedi!.')
       this.$router.push({name:'department-settings'})
       console.log( 'department delete',res);
  
   
        this.isLoading = false;
      });

    },
    // async UpdateDepartmentUser() {
    //   //this.isLoading = true;
    //   let userId = Number(this.$route.params.id);
    //   await Api("PUT", "DepartmentUsers/"+this.userDepartmentId,{
        
        
    //     departmentId:this.department,
    //     userId:Number(userId),
    //     isManager:this.userData.isManager
      
      
    //   }).then((res) => {
    //    alert('Kullanıcı güncellendi.')
    //     console.log( 'DepartmentUsers update',res);
        
        
       
    //   });
    // },
   async userUpdate(){
      let departmentId = this.$route.params.id

      
      await Api("PUT", "Department/"+departmentId,{
        name:this.departmentData.name,
        parentId:this.departmentParentId,
       
        //isDelete:userData.isDelete,
        //computerName:userData.computerName
      
      
      
      }).then((res) => {
       alert(res.data.message=='OK' ?'Departman bilgileri güncellendi.':'Departman bilgileri güncellenemedi.')
       this.$router.push({name:'department-settings'})
       console.log( 'department update',res);
        //this.UpdateDepartmentUser()
   
     });

    
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
